<template>
  <div class="upcoming_event">
    <div style="padding-bottom: 15px !important">
      <h4 class="section-title">Upcoming Events</h4>
    </div>
    <v-data-table
      :headers="headers"
      :items="upcomingEvents"
      :server-items-length="totalUpcomingEvents"
      @click:row="editItem"
      :options.sync="options"
      :loading="loading"
      :dense="true"
      :footer-props="{
        'items-per-page-options': [5],
      }"
      @update:options="fetch"
      :height="calculateTableHeight()"
    >
      <template v-slot:item.start_time="{ item }">
        <v-container fluid class="d-flex align-items-center">
          <div class="left-div">
            <user-avatar :avatar="item.event.banner" size="30" class="mr-4" />
          </div>
          <div class="right-div ml-2">
            <div>{{ item?.event?.title }}</div>
            <div class="second-line-text">
              {{ formatDate(item.start_time) }}
            </div>
          </div>
        </v-container>
      </template>
      <template v-slot:item.attendees="{ item }">
        {{ item.rsvp }}
      </template>
    </v-data-table>
    <div class="div__button_see_all">
      <v-btn class="button_see_all" @click="seeAll" text>
        see all <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import UserAvatar from '@/components/UserAvatar'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import FilterTableMixin from '@/utils/mixins/FilterTable'
import { dateFormat } from '@/utils/date'

export default {
  name: 'UpcomingEvent',

  mixins: [ControlsMixin, SnackbarMixin, ErrorHandlerMixin, FilterTableMixin],

  data() {
    return {
      loading: false,
      headers: [
        {
          text: 'Event',
          value: 'start_time',
        },
        {
          text: 'RVSP',
          align: 'center',
          value: 'attendees',
        },
      ],
      options: {
        sortBy: ['start_time'],
        sortDesc: [true],
      },
    }
  },

  created() {
    this.fetch()
  },

  components: {
    UserAvatar,
  },

  computed: {
    ...mapState({
      upcomingEvents: (state) => state.event.upcomingEvents,
      totalUpcomingEvents: (state) => state.event.totalUpcomingEvents,
    }),
  },

  methods: {
    ...mapActions({
      getUpcomingEvent: 'event/getUpcomingEvent',
    }),

    ...mapMutations({
      clearEvents: 'event/clearEventList',
    }),

    filterList(filterValue) {
      this.clearEvents()
      this.fetch(null, filterValue)
    },

    calculateTableHeight() {
      // Calculate the height based on the number of items per page (5 in this case)
      // You may need to adjust this calculation based on your specific styling and layout
      const itemHeight = 65 // Average height of a row, adjust as per your design
      const paginationHeight = 36 // Height of pagination controls
      const rowsPerPage = 5 // Number of rows per page
      return rowsPerPage * itemHeight + paginationHeight + 'px'
    },

    async fetch(options, page = 1) {
      if (this.loading) return

      let params = {
        page: options?.page || 1,
        sort: options?.sortBy[0]
          ? options.sortDesc[0]
            ? '-' + options.sortBy[0]
            : options.sortBy[0]
          : '-start_time',
        itemsPerPage: options?.itemsPerPage
          ? options?.itemsPerPage === -1
            ? this.totalUpcomingEvents
            : options.itemsPerPage
          : 5,
      }

      this.loading = true
      await this.getUpcomingEvent(params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },

    editItem(item) {
      this.$router.push({
        name: 'event.list.details',
        params: { id: item.event.id },
      })
    },

    seeAll() {
      this.$router.push({
        name: 'event.dashboard',
      })
    },

    formatDate(date) {
      return dateFormat(date, 'ddd, D MMM h:mm a')
    },
  },
}
</script>
<style lang="scss" scoped>
.upcoming_event .title {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 20px;
}
.div__button_see_all {
  text-align: right;
}
.button_see_all {
  text-transform: uppercase !important;
  color: #1976d2 !important;
  font-weight: 700;
  font-size: 12px;
  position: absolute;
  bottom: 0; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
  /* Other button styles */
}

.second-line-text {
  font-size: 0.8em;
}
</style>
