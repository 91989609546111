<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Hello {{ user.full_name }}</v-toolbar-title
      >

      <v-spacer></v-spacer>
      <v-btn
        v-if="hasPermissions"
        color="primary"
        class="appbar__btn notification-button"
        height="40px"
        right
        :to="{ name: 'notification.create' }"
      >
        <v-icon class="mr-2">{{ icons.add }}</v-icon>
        Notification
      </v-btn>
      <v-btn
        v-if="hasPermissions"
        color="primary"
        class="appbar__btn"
        height="40px"
        right
        :to="{ name: 'news.create' }"
      >
        <v-icon class="mr-2">{{ icons.add }}</v-icon>
        News Article
      </v-btn>
      <v-btn
        v-if="hasPermissions"
        color="primary"
        class="appbar__btn"
        height="40px"
        right
        :to="{ name: 'event.list.create' }"
      >
        <v-icon class="mr-2">{{ icons.add }}</v-icon>
        Event
      </v-btn>
      <v-btn
        v-if="hasPermissions"
        color="primary"
        class="appbar__btn"
        height="40px"
        right
        :to="{ name: 'perk.create' }"
      >
        <v-icon class="mr-2">{{ icons.add }}</v-icon>
        Perk
      </v-btn>
      <v-btn
        v-if="hasPermissions"
        color="primary"
        class="appbar__btn"
        height="40px"
        right
        :to="{ name: 'user.create' }"
      >
        <v-icon class="mr-2">{{ icons.add }}</v-icon>
        User
      </v-btn>
    </v-app-bar>

    <div class="home-page | mx-4">
      <div>
        <v-container key="center" fluid>
          <v-row align="stretch" class="mb-6">
            <v-col md="4">
              <v-card
                class="pa-4 ma-2 d-flex flex-column"
                style="
                  height: calculatecardHeight();
                  border-radius: 12px !important;
                  overflow: auto;
                  height: 100%;
                "
                outlined
                tile
              >
                <MaintenanceDonut />
              </v-card>
            </v-col>
            <v-col md="4">
              <v-card
                class="pa-4 ma-2"
                style="
                  height: calculatecardHeight();
                  border-radius: 12px !important;
                  overflow: auto;
                  height: 100%;
                "
                outlined
                tile
              >
                <UpcomingBookingRequest />
              </v-card>
            </v-col>
            <v-col md="4">
              <v-card
                class="pa-4 ma-2"
                style="
                  height: calculatecardHeight();
                  border-radius: 12px !important;
                  overflow: auto;
                  height: 100%;
                "
                outlined
                tile
              >
                <UpcomingEvent />
              </v-card>
            </v-col>
          </v-row>
          <v-row align="stretch">
            <v-col md="8">
              <v-card
                class="pa-4 ma-2"
                style="
                  height: calculatecardHeight();
                  border-radius: 12px !important;
                  overflow: auto;
                  height: 100%;
                "
                outlined
                tile
              >
                <NewMaintenance />
              </v-card>
            </v-col>
            <v-col md="4">
              <v-card
                class="pa-4 ma-2"
                style="
                  height: calculatecardHeight();
                  border-radius: 12px !important;
                  overflow: auto;
                  height: 100%;
                "
                outlined
                tile
              >
                <NewBookingRequest />
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import { mdiPlus } from '@mdi/js'
import { mapState } from 'vuex'
import UpcomingBookingRequest from '@/views/Home/Home/components/UpcomingBookingRequest'
import UpcomingEvent from '@/views/Home/Home/components/UpcomingEvent'
import MaintenanceDonut from '@/views/Home/Home/components/MaintenanceDoughnutChart'
import NewMaintenance from '@/views/Home/Home/components/NewMaintenanceRequest'
import NewBookingRequest from '@/views/Home/Home/components/NewBookingRequest'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'

export default {
  name: 'HomePage',
  components: {
    AppBarNavIcon,
    UpcomingBookingRequest,
    UpcomingEvent,
    MaintenanceDonut,
    NewMaintenance,
    NewBookingRequest,
  },

  data() {
    return {
      icons: {
        add: mdiPlus,
      },
    }
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      permissions: (state) => state.auth.permissions,
    }),

    hasPermissions() {
      return validatePermissions(
        [
          PERMISSION.NOTIFICATIONS_CREATE,
          PERMISSION.NEWS_CREATE,
          PERMISSION.EVENTS_CREATE,
          PERMISSION.PERKS_CREATE,
          PERMISSION.USERS_CREATE,
        ],
        this.permissions
      )
    },
  },

  methods: {
    calculatecardHeight() {
      const title = 35 // Height of card title
      const pads = 32 //
      const itemHeight = 65 // Average height of a row, adjust as per your design
      const paginationHeight = 36 // Height of pagination controls
      const rowsPerPage = 5 // Number of rows per page
      return rowsPerPage * itemHeight + paginationHeight + title + pads + 'px'
    },
  },
}
</script>
<style lang="scss" scoped>
.appbar__btn {
  margin-left: 20px;
  text-transform: uppercase !important;
  font-size: 14px;
  font-weight: 700;
}

.main-appbar .container_col {
  position: relative;
}

.home-page {
  h1 {
    font-size: 30px;
    font-weight: 700;
  }
}
</style>

<style lang="scss">
.home-page {
  .section-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;
    color: rgba(0, 0, 0, 1);
  }
}

.notification-button {
  position: relative;
}

.main-appbar {
  height: auto !important;
  min-height: 64px;
  .v-toolbar__content {
    flex-wrap: wrap;
    height: auto !important;
    gap: 10px;
  }

  .appbar__btn {
    @media (max-width: 540px) {
      margin-left: 0 !important;
    }
  }
}
</style>
